import Layout from "../components/Layout"


import LOGO_OUTDOOR from "./../images/logo-outdoor.png"
import SLOGAN_OUTDOOR from "./../images/slogan-outdoor.png"
import FACE_OUTDOOR from "./../images/carl.png"


import LOGO_INDOOR from "./../images/logo-indoor.png"
import SLOGAN_INDOOR from "./../images/slogan-indoor.png"
import FACE_INDOOR from "./../images/lynn.png"



import LOGO_SPORTFI from "./../images/logo-sportfi.png"
import SLOGAN_SPORTFI from "./../images/slogan-sportfi.png"
import FACE_SPORTFI from "./../images/mascot.png"

import { useState } from "react"

const Home = () => {
  const [active, setActive] = useState(0)
  return <Layout>
    <div className="home">
      <div className="home-wrap">
        <div className={"home-item home-item-outdoor " + (active === 0 ? 'active' : '')}
          onMouseEnter={() => setActive(0)}
        >
          <div className="logo">
            <img src={LOGO_OUTDOOR} />
          </div>
          <div className="slogan">
            <img src={SLOGAN_OUTDOOR} />
          </div>
          <div className="link">
            <a href="https://calo.run/"
              target="_blank"
              rel="noreferrer">JOIN NOW</a>
          </div>
          <div className="person">
            <img src={FACE_OUTDOOR} />
          </div>
        </div>
      </div>
      <div className="home-wrap">
        <div
          onMouseEnter={() => setActive(1)}
          className={"home-item home-item-indoor " + (active === 1 ? 'active' : '')}  >
          <div className="logo">
            <img src={LOGO_INDOOR} />
          </div>
          <div className="slogan">
            <img src={SLOGAN_INDOOR} />
          </div>
          <div className="link">
            <a target="_blank"
              rel="noreferrer"
              href="https://indoor.fun/">JOIN NOW</a>
          </div>

          <div className="person">
            <img src={FACE_INDOOR} />
          </div>
        </div>
      </div>
      <div className="home-wrap">
        <div
          onMouseEnter={() => setActive(2)}
          className={"home-item home-item-sportfi " + (active === 2 ? 'active' : '')}   >
          <div className="logo">
            <img src={LOGO_SPORTFI} />
          </div>
          <div className="slogan">
            <img src={SLOGAN_SPORTFI} />
          </div>
          <div className="link">
            <a target="_blank"
              rel="noreferrer"
              href="https://sportfi.win/">JOIN NOW</a>
          </div>
          <div className="person">
            <img src={FACE_SPORTFI} />
          </div>
        </div>
      </div>
    </div>
  </Layout >
}
export default Home