import React from "react";
import BANNER_BG from "../images/banner/bannerPOT.webp";
import BANNER_TITLE from "../images/banner/bannerTitle.webp";
import BTN_PLAY from "../images/banner/btnPlay.webp";
import BTN_JOIN from "../images/banner/btnJoin.webp";

const Banner = () => {
  return (
    <div className="banner">
      <div className="banner-wrap">
        <div className="banner-container">
          <img
            src={BANNER_BG}
            alt="pig-of-ton"
            loading="lazy"
            className="banner-pot"
          />
          <img
            src={BANNER_TITLE}
            alt="merge-gaming"
            className="banner-title"
            loading="lazy"
          />
          <a href="https://app.pigofton.com/" target="_blank" rel="noreferrer">
            <img
              src={BTN_PLAY}
              alt="play-for-free"
              className="banner-btn btn-play"
              loading="lazy"
            />
          </a>
          <a
            href="https://t.me/pigoftonchannel"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={BTN_JOIN}
              alt="join-community"
              loading="lazy"
              className="banner-btn btn-join"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Banner;
