import { Link } from 'react-router-dom'
import logo from './../logo.svg'

const Header = () => {
  return <div className="header">
    <div className="container">
      <div className="header-content">
        <div className="header-logo">
          <Link to="/">
            <img src={logo} />
          </Link>
        </div>
        {/* <div className="header-menu">
          <Link className="header-menu-item">
            News
          </Link>
          <Link className="header-menu-item">
            Hot Topic
          </Link>
          <Link className="header-menu-item">
            Project
          </Link>
        </div> */}
      </div>
    </div>
  </div >
}
export default Header
