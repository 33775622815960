import Banner from "./Banner";
import Header from "./Header";

const Layout = (props) => {
  return (
    <div className="main-page">
      <Header />
      <Banner />
      {props.children}
    </div>
  );
};
export default Layout;
